<template>
  <div id="app">
    <Header />
    <main>
      <p class="title">
        Данные кредиторы уже <span class="description__bold">предварительно одобрили займ</span>
      </p>
      <p class="description">
        Выберете <span class="description__bold">трех</span> кредиторов и внесите реквизиты
      </p>
      <Loans :offers="offers" />
    </main>
    <Footer/>
  </div>
</template>

<script>
import axios from 'axios';

import Header from '@/components/Header.vue';
import Loans from '@/components/Loans.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  data() {
    return {
      offers: {},
    };
  },
  created() {
    axios
      .get('/api/final')
      .then((response) => {
        if (Object.prototype.hasOwnProperty.call(response.data, 'targets')) {
          this.offers = Object.freeze(response.data.targets.default);
        }
      })
      .catch((error) => {
        throw new Error(error);
      });
  },
  components: {
    Loans,
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Rubik-Regular', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;

  main {
    max-width: 1240px;
    padding: 0 20px;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    @media screen and (max-width: 640px) {
      padding: 0 15px;
    }

    .title {
      font-size: 40px;
      line-height: 46px;
      max-width: 700px;
      margin: 0 auto 25px;

      span {
        font-family: 'Inter-Bold';
      }

      @media screen and (max-width: 640px) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    p.description {
      font-size: 16px;
      line-height: 24px;
      color: #828282;
      margin: 0;
    }
  }
}

.description__bold {
  font-weight: bold;
  color: #000;
}
</style>
